import Icon from '../../../components/UI/Icon';
import './recorrido.scss';
const iconLoader = require('../../../Assets/images/inicio/iconos/tour-360.json');

export const SectionRecorrido2 = () => {
	const properties = {
		loop: true,
		autoplay: true,
		animationData: iconLoader,
		speed: 0.7,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div className='recorrido relative ctn_recorrido c_p'>
			<div className='_recorrido relative'>
				<div className='_text_recorrido'>TU FUTURA VISTA</div>
				<a className='content-recorrido' href='https://praux3d.com/padovainmobiliaria/heliopanoramas/' target='_blank'>
					<img className='img-fluid' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/background-recorrido-virtual-tu-futura-vista.png')}></img>
					<div className='icon-recorrido-lottie'>
						<Icon properties={properties}></Icon>
					</div>
				</a>
				<img className='globe-top-recorrido' src={require('../../../Assets/images/inicio/main/globle-top-recorrido.png')}></img>
				<img className='globe-recorrido' src={require('../../../Assets/images/inicio/main/globe-bottom-recorrido.png')}></img>
			</div>
			<img className='w-full trazo-bottom' src={require('../../../Assets/images/inicio/underline/trazo-bottom.svg').default}></img>
		</div>
	);
};
